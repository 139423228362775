<sh-card>
  <sh-text
    size="header-2"
    [innerHTML]="
      (isEditMode ? 'devices.editWorkflow' : 'devices.addWorkflow') | translate
    "
  ></sh-text>
  <div class="flex-container">
    <div
      class="display-flex-column midrow"
      style="padding-top: 12px"
      *ngIf="!isEditMode"
    >
      <div class="display-flex-row">
        <sh-checkbox
          [label]="'devices.copyExistingWorkflow' | translate"
          (click)="copyExistingWorkflow = !copyExistingWorkflow"
        ></sh-checkbox>
        <sh-icon icon="information" size="xs" id="info-1"></sh-icon>
        <sh-tooltip
          label="Copy Existing Device"
          target="info-1"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div class="display-flex-row">
        <sh-dropdown
          *ngIf="!isEditMode"
          [disabled]="!copyExistingWorkflow"
          [label]="'devices.copyExistingWorkflow' | translate"
        >
          <sh-menu-item
            *ngFor="let workflow of workflowList"
            [label]="workflow.title | getLocaleText : globalLanguage?.key : true"
            (click)="copyDevice(workflow)"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div class="display-flex-column midrow">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="(isEditMode ? 'devices.workflowName' : 'devices.newWorkflowName') | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Workflow Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          mandatory
          [error]="!isWorkflowNameValid"
          error-message="Workflow with same name already exists."
          [label]="'devices.newWorkflowName' | translate"
          #inputName
        mandatory
        [label]="'workflowguides.workflows.titleCol' | translate"
          [value]="titleTrans"
          (value-changed)="checkForValidName($event.target.value)"
        ></sh-input-text>
      </div>
    </div>

    <!-- <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.equipmentType' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-3"></sh-icon>
        <sh-tooltip
          label="Equipment Type"
          target="info-3"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-dropdown
          #addressableGroupNameDropdown
          mandatory
          [label]="'devices.equipmentType' | translate"
          [value]="addressableGroupName"
          (value-changed)="addressableGroupName = $event.target.value"
        >
          <sh-menu-item
            *ngFor="let addressableGroupName of addressableGroupNames"
            [label]="addressableGroupName"
            [value]="addressableGroupName"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div> -->
  </div>

  <div class="flex-container">
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.description' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-4"></sh-icon>
        <sh-tooltip
          label="Description"
          target="info-4"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          textarea
          mandatory
          [label]="'devices.description' | translate"
          rows="6"
          #inputDesc
          [value]="descriptionTrans"
          (value-changed)="description = $event.target.value"
          [error]="description?.length >1000"
          error-message="Word count is more than 1000"
        ></sh-input-text>
        <div class="float-right">
          <sh-text size="body-2" color="secondary"
            >Word Count: {{ description?.length }}/1000</sh-text
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex-container">
    <div class="display-flex-column midrow">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.businessLine' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-5"></sh-icon>
        <sh-tooltip
          label="Businessline"
          target="info-5"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-dropdown
          #businessLineDropdown
          mandatory
          [disabled]="isEditMode && disableBusinessLineDropDown"
          [label]="'devices.businessLine' | translate"
          [value]="businessLine"
          (value-changed)="businessLine = $event.target.value"
        >
          <sh-menu-item
            *ngFor="let businessLine of businessLines"
            [label]="businessLine"
            [value]="businessLine"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div class="display-flex-column midrow">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'workflowguides.workflows.applicationType' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-5"></sh-icon>
        <sh-tooltip
          label="ApplicationType"
          target="info-5"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-dropdown
          #applicationTypeDropdown
          mandatory
          [label]="'workflowguides.workflows.applicationType' | translate"
          [value]="applicationType"
          (value-changed)="applicationType = $event.target.value"
        >
          <sh-menu-item
            *ngFor="let applicationType of applicationTypes"
            [label]="applicationType"
            [value]="applicationType"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
      <!-- <div class="display-flex-column column" >
      <sh-checkbox
        [active]="arInitial"
        [label]="'workflowguides.workflows.arCol' | translate"
        (click)="ar = !ar"
      ></sh-checkbox>
    </div> -->
  </div>

  <div class="flex-container">
    <div class="display-flex-column midrow">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'workflowguides.workflows.patientPosition' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-5"></sh-icon>
        <sh-tooltip
          label="PatientPosition"
          target="info-5"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-dropdown
          #patientPositionDropdown
          mandatory
          [label]="'workflowguides.workflows.patientPosition' | translate"
          [value]="patientPosition"
          (value-changed)="patientPosition = $event.target.value"
        >
          <sh-menu-item
            *ngFor="let position of patientPositions"
            [label]="position"
            [value]="position"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
  </div>

  <div class="flex-container" style="width: 66.66%">
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          color="primary"
          [innerHTML]="'devices.uploadImage' | translate"
        ></sh-text>
      </div>

      <div>
        <img id="workflowimage" *ngIf="thumbnail && !thumbnailChanged" [src]="thumbnail | s3Image | async" />
        <img #previewImage *ngIf="thumbnail && thumbnailChanged" />
        <sh-text #inputFileEditbox [value]="thumbnailBasename">{{
          thumbnailBasename
        }}</sh-text>
      </div>

      <divs
      *ngIf="!thumbnail"
        class="sh-empty-state display-flex-column"
        style="align-items: center; padding: 60px"
        (dragover)="preventDefault($event)"
        (drop)="drop($event)"
      >
        <div>
          <sh-icon icon="page" size="l" class="empty-state-icon"> </sh-icon>
        </div>
        <div>
          <sh-text class="empty-state-text" size="body-1">
            Choose a file or drag & drop it here
          </sh-text>
        </div>
      </divs>
      <div class="padding-top-12">
        <sh-button
          class="float-right"
          size="m"
          [label]="'devices.uploadFile' | translate"
          (click)="selectFile()"
        ></sh-button>
      </div>
    </div>
  </div>

  <div class="buttons">
    <sh-button
      size="m"
      [disabled]="
        !(inputName.value && isWorkflowNameValid) ||
        !inputDesc.value || inputDesc.value.length > 1000 ||
        !businessLineDropdown.value ||
        !applicationTypeDropdown.value ||
        !patientPositionDropdown.value"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.close' | translate"
    ></sh-button>
  </div>
</sh-card>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
