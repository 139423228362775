import { Component, EventEmitter, OnInit } from '@angular/core';
import { WorkflowSimulationLockState } from '../../../../../../../../../../api/src/app/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/workflowSimulationLockState.enum';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-edit-axis',
  templateUrl: './edit-axis.component.html',
  styleUrls: ['./edit-axis.component.scss'],
})
export class EditAxisComponent implements OnInit {
  public saveEmitter = new EventEmitter<any>();
  public cancelEmitter = new EventEmitter();

  public key: string;
  public lockState: string;
  public inverted: boolean;
  public startPosition: number;
  public targetRequired: boolean;
  public targetPosition: number;
  public targetMargin: number;
  public id: number;

  public lockStates = Object.keys(WorkflowSimulationLockState).filter((k) =>
    Number.isNaN(Number(k))
  );

  public constructor(
    public readonly workflowGuidesService: WorkflowGuidesService
  ){}

  ngOnInit(): void {}

  public init(axis: any) {
    this.key = axis.key;
    this.lockState =
      WorkflowSimulationLockState[axis.lockState] ||
      WorkflowSimulationLockState.Locked.toString();
    this.inverted = axis.inverted || false;
    this.startPosition = axis.startPosition || '0';
    this.targetRequired = axis.targetRequired;
    this.targetPosition = axis.targetPosition || '0';
    this.targetMargin = axis.targetMargin || '0';
    this.id = axis.id;
  }

  public async save(): Promise<void> {
    this.targetPosition = this.targetRequired ? this.targetPosition : null;
    this.targetMargin = this.targetRequired ? this.targetMargin : null;

    this.saveEmitter?.emit({
      id: this.id,
      key: this.key,
      lockState: WorkflowSimulationLockState[this.lockState],
      inverted: this.inverted,
      startPosition: this.startPosition,
      targetRequired: this.targetRequired,
      targetPosition: this.targetPosition,
      targetMargin: this.targetMargin
    });
  }

  public async cancel(): Promise<void> {
    this.cancelEmitter?.emit();
  }
}
