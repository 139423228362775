import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { DevicesService } from './devices.service';
import { LanguageService } from '../services/language.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { Subscription } from 'rxjs';
import { BusinessLineUtility } from '@backend/webapp/shared/businessLine/business-line.utility';
import { DeviceVersionsService } from './device-versions/device-versions.service';
import { ParamsService } from '../services/params.service';
import { Device } from '@backend/api/Device/device.model';
import { SpinnerService } from '../spinner/spinner.service';
import { WorkflowVersionService } from '../workflowguides/workflows/workflow-versions/workflow-version.service';
import { NotificationService } from '../shared/notification/notification.service';

@Component({
  selector: 'backend-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy {
  public statusValues = this.devicesService.getStatusEnumKeys();
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  protected readonly BusinessLineUtility = BusinessLineUtility;
  editDeviceHasChanges: boolean = true;
  languageSubscription: Subscription;
  searchString = '';
  isAscending = true;
  displayedDevices: any[] = [];
  filteredAndSortedDevices: any[] = [];
  allDevices: any[] = [];
  pageIndex = 1;
  itemsPerPage = 20;
  showDeleteDeviceModal = false;
  deviceTobeDeleted: Device;
  deleteReleaseVersionReq = false;
  deleteDraftVersionReq = false;
  deleteDeviceWithoutVersion = false;
  workflowsVersionsList: any[] = [];

  public constructor(
    public devicesService: DevicesService,
    public languageService: LanguageService,
    public router: Router,
    public localization: LocalizationService,
    public deviceVersionService: DeviceVersionsService,
    public paramsService: ParamsService,
    public spinnerService: SpinnerService,
    public workflowVersionService: WorkflowVersionService,
    public notificationService: NotificationService
  ) {
    this.devicesService.devices$?.subscribe(() => {
      this.allDevices = this.devicesService.devices;
      this.displayedDevices = this.devicesService.devices;
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.localization.getLanguages();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
        }
      }
    );
    this.editDeviceHasChanges = this.devicesService.getEditDeviceState('hasChanges') ?? true;
    if (this.editDeviceHasChanges){                 
      await this.devicesService.updateDevices(true);
      this.allDevices = this.devicesService.devices;
    }
    else{
      this.devicesService.resetEditDeviceState();
    }
    this.workflowsVersionsList =
      await this.workflowVersionService.getAllDeviceVersionsIds();
    this.filteredAndSortedDevices = this.allDevices;
    this.sortDevicesByName();
    this.manageDevicesInfiniteScroll();
  }

  public getallEquipmentsCount(): string {
    if (this.allDevices?.length === 0) {
      return '';
    }

    return `(${this.allDevices?.length})`;
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public promtDeleteDevice(device: Device): void {
    this.showDeleteDeviceModal = true;
    //Reset the checkbox values
    this.deleteReleaseVersionReq = false;
    this.deleteDraftVersionReq = false;
    this.deleteDeviceWithoutVersion = false;

    this.deviceTobeDeleted = device;
    if (device.deviceReleasedVersion && !device.deviceDraftVersion) {
      this.deleteReleaseVersionReq = true;
    }
    if (device.deviceDraftVersion && !device.deviceReleasedVersion) {
      this.deleteDraftVersionReq = true;
    }
    if (device.deviceReleasedVersion && device.deviceDraftVersion) {
      this.deleteReleaseVersionReq = false;
      this.deleteDraftVersionReq = false;
    }
    if (!device.deviceReleasedVersion && !device.deviceDraftVersion) {
      this.deleteDeviceWithoutVersion = true;
    }
  }

  public async deleteDevice(): Promise<void> {
    this.showDeleteDeviceModal = false;
    this.spinnerService.showSpinner();

    if (this.deleteDeviceWithoutVersion) {
      await this.devicesService.deleteDevice(this.deviceTobeDeleted?.id, true);
      return;
    }

    let isDraftHasDependency = false;
    let isReleaseHasDependency = false;

    let deviceDraftVersions: any[] = [];
    let deviceReleaseVersions: any[] = [];

    //Get all release and draft versions for device to be deleted.
    if (this.deviceTobeDeleted.deviceDraftVersion) {
      deviceDraftVersions.push(this.deviceTobeDeleted.deviceDraftVersion);
    }

    if (this.deviceTobeDeleted.deviceReleasedVersion) {
      deviceReleaseVersions = this.deviceTobeDeleted.deviceVersions.filter(
        (deviceVersion) =>
          deviceVersion.id !== this.deviceTobeDeleted.deviceDraftVersion?.id
      );
    }

    //Checking dependency of release and draft version.
    if (this.deleteDraftVersionReq) {
      isDraftHasDependency = await this.checkForWorkflowDependency(
        deviceDraftVersions
      );
    }

    if (this.deleteReleaseVersionReq) {
      isReleaseHasDependency = await this.checkForWorkflowDependency(
        deviceReleaseVersions
      );
    }

    if (this.deleteReleaseVersionReq && this.deleteDraftVersionReq) {
      await this.deleteReleaseAndDraftVersion(
        isReleaseHasDependency,
        isDraftHasDependency,
        deviceReleaseVersions
      );
    } else if (this.deleteReleaseVersionReq && !this.deleteDraftVersionReq) {
      await this.deleteReleaseVersion(
        isReleaseHasDependency,
        deviceReleaseVersions
      );
    } else if (this.deleteDraftVersionReq && !this.deleteReleaseVersionReq) {
      await this.deleteDraftVersion(isDraftHasDependency);
    }

    this.deviceTobeDeleted = null;
    this.deleteReleaseVersionReq = false;
    this.deleteDraftVersionReq = false;
    this.deleteDeviceWithoutVersion = false;
    this.spinnerService.hideSpinner();
  }

  public async deleteReleaseAndDraftVersion(
    isReleaseHasDependency: boolean,
    isDraftHasDependency: boolean,
    deviceReleaseVersions: any[]
  ): Promise<void> {
    if (isReleaseHasDependency && isDraftHasDependency) {
      this.notificationService.alert(
        'general.warning',
        'devices.deleteReleaseAndDraftWarning',
        null
      );
    } else if (!isReleaseHasDependency && !isDraftHasDependency) {
      await this.devicesService.deleteDevice(this.deviceTobeDeleted?.id, true);
    } else if (!isDraftHasDependency) {
      await this.deviceVersionService.deleteDeviceVersion(
        this.deviceTobeDeleted.deviceDraftVersion?.id,
        true
      );
      await this.devicesService.updateDevices();

      this.notificationService.alert(
        'general.warning',
        'devices.deleteReleaseWarning',
        null
      );
    } else if (!isReleaseHasDependency) {
      for (const version of deviceReleaseVersions) {
        await this.deviceVersionService.deleteDeviceVersion(version.id, false);
      }
      await this.devicesService.updateDevices();
      this.notificationService.alert(
        'general.warning',
        'devices.deleteDraftWarning',
        null
      );
    }
  }

  public async deleteReleaseVersion(
    isReleaseHasDependency: boolean,
    deviceReleaseVersions: any[]
  ): Promise<void> {
    if (isReleaseHasDependency) {
      this.notificationService.alert(
        'general.warning',
        'devices.deleteReleaseWarning',
        null
      );
    } else if (
      !isReleaseHasDependency &&
      !this.deviceTobeDeleted.deviceDraftVersion
    ) {
      await this.devicesService.deleteDevice(this.deviceTobeDeleted?.id, true);
    } else if (
      !isReleaseHasDependency &&
      this.deviceTobeDeleted.deviceDraftVersion
    ) {
      for (const version of deviceReleaseVersions) {
        await this.deviceVersionService.deleteDeviceVersion(version.id, false);
      }
      await this.devicesService.updateDevices();
    }
  }

  public async deleteDraftVersion(
    isDraftHasDependency: boolean
  ): Promise<void> {
    if (isDraftHasDependency) {
      this.notificationService.alert(
        'general.warning',
        'devices.deleteDraftWarning',
        null
      );
    } else if (
      !isDraftHasDependency &&
      !this.deviceTobeDeleted.deviceReleasedVersion
    ) {
      await this.devicesService.deleteDevice(this.deviceTobeDeleted?.id, true);
    } else if (
      !isDraftHasDependency &&
      this.deviceTobeDeleted.deviceReleasedVersion
    ) {
      await this.deviceVersionService.deleteDeviceVersion(
        this.deviceTobeDeleted.deviceDraftVersion?.id,
        true
      );
      await this.devicesService.updateDevices();
    }
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.pageIndex = 1;
    this.searchDevicesByNameOrDescription();
    this.manageDevicesInfiniteScroll();
  }

  public toggleSort(): void {
    this.isAscending = !this.isAscending;
    this.pageIndex = 1;
    this.sortDevicesByName();
    this.manageDevicesInfiniteScroll();
  }

  public onScrollEnd(): void {
    this.pageIndex = this.pageIndex + 1;
    this.manageDevicesInfiniteScroll();
  }

  private sortDevicesByName(): void {
    this.filteredAndSortedDevices = this.filteredAndSortedDevices?.sort(
      (a, b) =>
        (this.isAscending ? -1 : 1) *
        this.getTranslation(b.deviceDescription.name).localeCompare(
          this.getTranslation(a.deviceDescription.name)
        )
    );
  }

  private searchDevicesByNameOrDescription(): void {
    if (!this.searchString) {
      this.filteredAndSortedDevices = this.allDevices;
    } else {
      const searchText = this.searchString.toLocaleLowerCase();
      this.filteredAndSortedDevices = this.allDevices.filter(
        (device) =>
          this.getTranslation(device.deviceDescription.name)
            .toLocaleLowerCase()
            .includes(searchText) ||
          this.getTranslation(device.deviceDescription.description)
            .toLocaleLowerCase()
            .includes(searchText)
      );
    }

    this.sortDevicesByName();
  }

  manageDevicesInfiniteScroll(): void {
    const startIndex = (this.pageIndex - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (this.pageIndex == 1) {
      this.displayedDevices = [];
    }

    for (let i = startIndex; i < endIndex; i++) {
      if (i < this.filteredAndSortedDevices?.length) {
        this.displayedDevices.push(this.filteredAndSortedDevices[i]);
      }
    }
  }

  private getTranslation(translations: any[]): string {
    const translation = this.languageService.getDefaultTranslationOrByKey(
      translations,
      this.globalLanguage
        ? this.globalLanguage.key
        : Constants.DEFAULT_GLOBAL_LANGUAGE.key
    );

    return translation;
  }

  public async checkForWorkflowDependency(
    deviceVersions: any[]
  ): Promise<boolean> {
    let isDeviceHasWorkflowDependency = false;
    for (const deviceVersion of deviceVersions) {
      if (
        this.workflowsVersionsList.find(
          (workflow) => workflow.deviceVersionId === deviceVersion.id
        )
      ) {
        isDeviceHasWorkflowDependency = true;
        break;
      }
    }
    return isDeviceHasWorkflowDependency;
  }
}
