import { PatientPosition } from "libs/interfaces/src/lib/enums/patientPosition.enum";

export class PatientPositionUtility {
    public static patientPositions: Array<{ name: string; value: string }> = null;

    public static getPatientPositions(): string[] {
        return Object.keys(PatientPosition);
    }

    private static getAllPatientPositions() {
        if(PatientPositionUtility.patientPositions !== null) {
            return PatientPositionUtility.patientPositions;
        }

        PatientPositionUtility.patientPositions = Object.keys(PatientPosition).map((key) => {
            return { name: key, value: PatientPosition[key] };
        });
    }

    public static getPatientPositionByValue(value: string): string {
        PatientPositionUtility.getAllPatientPositions();
        return PatientPositionUtility.patientPositions.find((position) => position.value === value).name;
    }
}