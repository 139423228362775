<div>
  <sh-text
    size="title-1"
    [innerHTML]="
      'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.edit'
        | translate
    "
  ></sh-text>

  <div class="content">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.key'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [value]="key"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.key'
            | translate
        "
        (value-changed)="key = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.lockState'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [value]="lockState"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.lockState'
            | translate
        "
        (value-changed)="lockState = $event.target.value"
      >
        <sh-menu-item
          *ngFor="let state of lockStates"
          [label]="state"
        ></sh-menu-item>
      </sh-dropdown>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.inverted'
            | translate
        "
      ></sh-text>
      <sh-checkbox
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.inverted'
            | translate
        "
        [active]="inverted"
        (checked)="inverted = $event.target.active"
      ></sh-checkbox>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.startPosition'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [value]="startPosition"
        (value-changed)="startPosition = $event.target.value"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.startPosition'
            | translate
        "
      ></sh-input-number>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetRequired'
            | translate
        "
      ></sh-text>
      <sh-checkbox
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetRequired'
            | translate
        "
        [active]="targetRequired"
        (checked)="targetRequired = $event.target.active"
      ></sh-checkbox>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetPosition'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive() || (!targetRequired)"
        [value]="targetPosition"
        (value-changed)="targetPosition = $event.target.value"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetPosition'
            | translate
        "
      ></sh-input-number>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetMargin'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive() || (!targetRequired)"
        [value]="targetMargin"
        (value-changed)="targetMargin = $event.target.value"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.targetMargin'
            | translate
        "
      ></sh-input-number>
    </div>
  </div>

  <div slot="footer" class="buttons">
    <sh-button *ngIf="!workflowGuidesService.isReleaseVersionActive()"
      [disabled]=""
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>
